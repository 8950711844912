/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {MapInfo, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-dry-out-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-dry-out-hearing-aids",
    "aria-label": "how to dry out hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to dry out hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Proper care and maintenance of hearing aids are essential to ensure they function optimally and have a longer lifespan. Moisture is a common enemy of hearing aids, as it can damage sensitive electronic components and affect their performance. Drying out hearing aids regularly helps prevent moisture-related issues and extends their usability."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "methods-to-dry-out-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#methods-to-dry-out-hearing-aids",
    "aria-label": "methods to dry out hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Methods to dry out hearing aids:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Use a soft cloth"), ": Wipe away any water, rain, sweat, etc. on the outside of your hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Hearing aid dehumidifiers:"), " Specialized dehumidifiers are designed specifically for drying hearing aids. These devices typically use either gentle heat or desiccant materials to absorb moisture from the hearing aids. Place the hearing aids inside the dehumidifier overnight or for a few hours to effectively remove moisture."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Drying kits:"), " Drying kits are portable and convenient options for drying hearing aids, especially when traveling. Similar to dehumidifiers, drying kits employ heat or desiccant properties to remove moisture. They often come with carrying cases that double as drying containers for the hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Open battery door:"), " When not in use, open the battery door of the hearing aids to allow airflow. Removing the batteries also helps prevent corrosion and extends battery life. Allowing air circulation helps evaporate any accumulated moisture inside the hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Silica gel packets:"), " Silica gel packets are small sachets containing silica beads, which are excellent desiccants. Place a few silica gel packets in a container with the hearing aids to absorb moisture. Ensure the silica gel packets are kept away from children and pets, as they are not edible."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Avoid heat sources:"), " While it may be tempting to use a hairdryer, oven, or microwave to dry out hearing aids quickly, these methods can damage the delicate electronics. High temperatures can melt or warp the plastic casing and components of the hearing aids. Stick to gentle, controlled drying methods to avoid causing harm."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By incorporating these drying methods into your routine care for hearing aids, you can effectively prevent moisture-related issues and ensure they continue to function at their best. Regular maintenance and proper storage play crucial roles in prolonging the life of your hearing aids and maximizing your hearing experience."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-assistance-from-a-hearcom-hearing-specialist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-assistance-from-a-hearcom-hearing-specialist",
    "aria-label": "get assistance from a hearcom hearing specialist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get assistance from a hear.com hearing specialist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Noticed any changes in your hearing aid performance or have questions about their maintenance? Feel free to reach out to hear.com for assistance. We work with the top 2% of audiologists in the US, offering state-of-the-art hearing aid technology and personalized support throughout your journey towards better hearing. Don't hesitate to contact us for expert guidance and solutions tailored to your needs."), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Get assistance from hearing specialist",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
